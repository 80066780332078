const roles = {
    page_title: 'Roles',
    title: 'Roles',
    subtitle: 'Roles include permissions that determine what a user can do. RBAC uses roles to give users access to resources for specific actions.',
    create: 'Create Role',
    role_name: 'Role',
    role_description: 'Description',
    role_name_placeholder: 'Enter your role name',
    role_description_placeholder: 'Enter your role description',
    assigned_users: 'Assigned users',
    assign_permissions: 'Assign permissions',
    create_role_title: 'Create Role',
    create_role_description: 'Create and manage roles for your applications. Roles contain collections of permissions and can be assigned to users.',
    create_role_button: 'Create Role',
    role_created: 'The role {{name}} has been successfully created.',
    search: 'Search by role name, description or ID',
    placeholder_title: 'Roles',
    placeholder_description: 'Roles are a grouping of permissions that can be assigned to users. Be sure to add permission first before create roles.',
};
export default Object.freeze(roles);
