const profile = {
    page_title: 'Настройки аккаунта',
    title: 'Настройки аккаунта',
    description: 'Измените настройки своего аккаунта и управляйте своей личной информацией здесь, чтобы обеспечить безопасность своего аккаунта.',
    settings: {
        title: 'Настройки профиля',
        profile_information: 'Информация профиля',
        avatar: 'Аватар',
        name: 'Имя',
        username: 'Имя пользователя',
    },
    link_account: {
        title: 'СВЯЗАТЬ АККАУНТ',
        email_sign_in: 'Войти по электронной почте',
        email: 'Электронная почта',
        social_sign_in: 'Войти через социальные сети',
        link_email: 'Связать адрес электронной почты',
        link_email_subtitle: 'Свяжите свою электронную почту для входа или помощи в восстановлении аккаунта.',
        email_required: 'Адрес электронной почты обязателен',
        invalid_email: 'Неправильный адрес электронной почты',
        identical_email_address: 'Введенный адрес электронной почты идентичен текущему',
        anonymous: 'Анонимный',
    },
    password: {
        title: 'ПАРОЛЬ И БЕЗОПАСНОСТЬ',
        password: 'Пароль',
        password_setting: 'Настройка пароля',
        new_password: 'Новый пароль',
        confirm_password: 'Подтвердите пароль',
        enter_password: 'Введите текущий пароль',
        enter_password_subtitle: 'Для защиты безопасности вашей учетной записи подтвердите, что это вы. Пожалуйста, введите ваш текущий пароль перед изменением.',
        set_password: 'Установить пароль',
        verify_via_password: 'Проверить через пароль',
        show_password: 'Показать пароль',
        required: 'Пароль обязателен',
        min_length: 'Пароль должен содержать не менее {{min}} символов',
        do_not_match: 'Пароли не совпадают. Попробуйте еще раз.',
    },
    code: {
        enter_verification_code: 'Введите код подтверждения',
        enter_verification_code_subtitle: 'Код подтверждения был отправлен на <strong>{{target}}</strong>',
        verify_via_code: 'Проверить через код подтверждения',
        resend: 'Повторно отправить код подтверждения',
        resend_countdown: 'Отправить повторно через {{countdown}} секунд',
    },
    delete_account: {
        title: 'УДАЛИТЬ АККАУНТ',
        label: 'Удалить аккаунт',
        description: 'Удаление вашего аккаунта приведет к удалению всей вашей личной информации, пользовательских данных и конфигураций. Это действие нельзя будет отменить.',
        button: 'Удалить аккаунт',
        dialog_paragraph_1: 'Мы сожалеем, что вы хотите удалить свой аккаунт. Удаление вашего аккаунта навсегда удалит всю информацию, включая данные пользователя, журналы и настройки, и это действие нельзя будет отменить. Пожалуйста, сделайте резервную копию важных данных перед продолжением.',
        dialog_paragraph_2: 'Чтобы продолжить процесс удаления аккаунта, отправьте электронное письмо в нашу службу поддержки на адрес <a>{{mail}}</a> с темой «Запрос на удаление аккаунта». Мы поможем и убедимся в правильном удалении всех ваших данных из нашей системы.',
        dialog_paragraph_3: 'Благодарим вас за выбор облачного сервиса Logto. Если у вас есть какие-либо дополнительные вопросы или проблемы, не стесняйтесь обращаться к нам.',
    },
    set: 'Установить',
    change: 'Изменить',
    link: 'Связать',
    unlink: 'Разъединить',
    not_set: 'Не установлено',
    change_avatar: 'Изменить аватар',
    change_name: 'Изменить имя',
    change_username: 'Изменить имя пользователя',
    set_name: 'Установить имя',
    email_changed: 'Адрес электронной почты изменен.',
    password_changed: 'Пароль изменен.',
    updated: '{{target}} обновлен.',
    linked: '{{target}} связан.',
    unlinked: '{{target}} разъединен.',
    email_exists_reminder: 'Этот адрес электронной почты {{email}} связан с существующим аккаунтом. Свяжите другой адрес электронной почты здесь.',
    unlink_confirm_text: 'Да, разъединить',
    unlink_reminder: 'Пользователи не смогут войти в аккаунт с помощью <span></span> , если вы его отсоедините. Вы уверены, что хотите продолжить?',
};
export default Object.freeze(profile);
