const roles = {
    page_title: 'Títulos das funções',
    title: 'Funções',
    subtitle: 'As funções incluem permissões que determinam o que um usuário pode fazer. O RBAC usa funções para dar aos usuários acesso a recursos para ações específicas.',
    create: 'Criar função',
    role_name: 'Função',
    role_description: 'Descrição',
    role_name_placeholder: 'Insira o nome da sua função',
    role_description_placeholder: 'Insira a descrição da sua função',
    assigned_users: 'Usuários atribuídos',
    assign_permissions: 'Atribuir permissões',
    create_role_title: 'Criar função',
    create_role_description: 'Crie e gerencie funções para suas aplicações. As funções contêm coleções de permissões e podem ser atribuídas a usuários.',
    create_role_button: 'Criar função',
    role_created: 'A função {{name}} foi criada com sucesso.',
    search: 'Pesquisar pelo nome, descrição ou ID da função',
    placeholder_title: 'Funções',
    placeholder_description: 'As funções são um agrupamento de permissões que podem ser atribuídas a usuários. Certifique-se de adicionar as permissões antes de criar funções.',
};
export default Object.freeze(roles);
