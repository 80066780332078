@use '@/scss/underscore' as _;

.container {
  padding: _.unit(3) _.unit(8);
  background-color: var(--color-info-container);
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: _.unit(6);
  margin-bottom: _.unit(4);

  .image {
    flex-shrink: 0;
    width: 100px;
    height: 100px;
  }

  .title {
    font: var(--font-title-2);
  }

  .description {
    font: var(--font-body-2);
    color: var(--color-text-secondary);
    margin-top: _.unit(1);

    .textLink {
      text-underline-offset: 3px;
    }
  }
}
