const roles = {
    page_title: '角色',
    title: '角色',
    subtitle: 'RBAC 是一種訪問控制方法，它使用角色來決定用戶可以做什麼事情，包括授予用戶訪問特定資源的權限。',
    create: '建立角色',
    role_name: '角色名稱',
    role_description: '描述',
    role_name_placeholder: '輸入你的角色名稱',
    role_description_placeholder: '輸入你的角色描述',
    assigned_users: '已分配的使用者',
    assign_permissions: '分配權限',
    create_role_title: '建立角色',
    create_role_description: '為你的應用程式建立和管理角色。角色包含權限集合，並可以分配給使用者。',
    create_role_button: '建立角色',
    role_created: '角色 {{name}} 已成功建立。',
    search: '按角色名稱、描述或 ID 搜尋',
    placeholder_title: '角色',
    placeholder_description: '角色是可以分配給使用者的權限分組。在建立角色之前，請確保先新增權限。',
};
export default Object.freeze(roles);
