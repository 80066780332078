const others = {
    terms_of_use: {
        title: '이용 약관',
        terms_of_use: '이용 약관 URL',
        terms_of_use_placeholder: 'https://your.terms.of.use/',
        privacy_policy: '개인정보 처리방침 URL',
        privacy_policy_placeholder: 'https://your.privacy.policy/',
    },
    languages: {
        title: '언어',
        enable_auto_detect: '자동 감지 활성화',
        description: '사용자의 언어 설정을 감지하고, 해당 언어로 자동으로 변경해요. 직접 번역하여 새로운 언어를 추가할 수도 있어요.',
        manage_language: '언어 관리',
        default_language: '기본 언어',
        default_language_description_auto: '사용자의 언어를 지원하지 않을 경우, 기본 언어로 사용자에게 보여줘요.',
        default_language_description_fixed: '자동 감지가 꺼져있을 경우, 기본 언어로만 사용자에게 보여줘요. 더욱 나은 경험을 위해, 자동 감지를 켜 주세요.',
    },
    manage_language: {
        title: '언어 관리',
        subtitle: '언어와 번역을 추가하여 제품 경험을 현지화해요. 사용자의 기여를 기본 언어로 설정할 수 있어요.',
        add_language: '언어 추가',
        logto_provided: 'Logto 제공',
        key: '키',
        logto_source_values: 'Logto 소스 값',
        custom_values: '사용자 정의 값',
        clear_all_tip: '모든 값 삭제',
        unsaved_description: '이 페이지를 벗어날 경우, 변경점이 적용되지 않아요.',
        deletion_tip: '언어 삭제',
        deletion_title: '추가된 언어를 삭제할까요?',
        deletion_description: '삭제된 후에 사용자들이 더 이상 해당 언어로 볼 수 없어요.',
        default_language_deletion_title: '기본 언어는 삭제할 수 없어요.',
        default_language_deletion_description: '{{language}} 언어는 기본 언어로 설정되어 있어요. 기본 언어를 변경한 후에 삭제할 수 있어요.',
    },
    advanced_options: {
        title: '고급 옵션',
        enable_user_registration: '회원가입 활성화',
        enable_user_registration_description: '사용자 등록을 활성화하거나 비활성화해요. 비활성화된 후에도 사용자를 관리 콘솔에서 추가할 수 있지만 사용자는 더 이상 로그인 UI를 통해 계정을 설정할 수 없어요.',
    },
};
export default Object.freeze(others);
