@use '@/scss/underscore' as _;

.imageFieldHeadline {
  margin-bottom: _.unit(2);
}

.description {
  color: var(--color-text-secondary);
  font: var(--font-body-2);
  margin-top: _.unit(0.5);
}
